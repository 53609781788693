.form-look .card {
  min-width: 450px;
}
.form-look .card form {
  margin-top: 2rem;
}
.form-look .card .field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .form-look .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-look .card {
    width: 100%;
    min-width: 0;
  }
}
/* The "Forgot password" text */
span.psw {
  float: right;
  padding-top: 16px;
}
span.rgs {
  float: left;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  span.rgs {
    display: block;
    float: none;
  }
}
.form-look .card .refreshB {
  float: right;
}

.customer-badge {
  border-radius: 2px;
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;

  &.status-UPDATE_COMPLETED {
    background: #c8e6c9;
    color: #256029;
  }

  &.status-UPDATE_FAILED {
    background: #ffcdd2;
    color: #c63737;
  }

  &.status-NOT_AVAILABLE {
    background: #feedaf;
    color: #8a5340;
  }

  &.status-UPDATE_REQUESTED {
    background: #b3e5fc;
    color: #23547b;
  }

  &.status-UPDATE_INPROGRESS {
    background: #eccfff;
    color: #694382;
  }

  &.status-proposal {
    background: #ffd8b2;
    color: #805b36;
  }
}
