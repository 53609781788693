.form-look .card {
  min-width: 450px;
}
.form-look .card form {
  margin-top: 2rem;
}
.form-look .card .field {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 960px) {
  .form-look .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-look .card {
    width: 100%;
    min-width: 0;
  }
}
/* The "Forgot password" text */
span.psw {
  float: right;
  padding-top: 16px;
}
span.rgs {
  float: left;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.psw {
    display: block;
    float: none;
  }
  span.rgs {
    display: block;
    float: none;
  }
}
.form-look .card .refreshB {
  float: right;
}

.overlaypanel-demo .select-product-button {
  min-width: 15rem;
}

.overlaypanel-demo .product-image {
  width: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
