.multiselect-demo .p-multiselect {
  min-width: 15rem;
}

.multiselect-demo .multiselect-custom .p-multiselect-label:not(.p-placeholder):not(.p-multiselect-items-label) {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.multiselect-demo .multiselect-custom .country-item-value {
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: inline-flex;
  margin-right: 0.5rem;
  background-color: var(--primary-color);
  color: var(--primary-color-text);
}

.multiselect-demo .multiselect-custom .country-item-value img.flag {
  width: 17px;
}
.card {
  margin-top: 10px;
}
