.form-look .card {
  min-width: 450px;
}
.form-look .card form {
  margin-top: 2rem;
}
.form-look .card .field {
  margin-bottom: 2rem;
  margin-top: 2rem;
}
@media screen and (max-width: 960px) {
  .form-look .card {
    width: 80%;
  }
}
@media screen and (max-width: 640px) {
  .form-look .card {
    width: 100%;
    min-width: 0;
  }
}
/* The "Forgot password" text */
span.login {
  float: right;
  padding-top: 16px;
}

/* Change styles for span and cancel button on extra small screens */
@media screen and (max-width: 300px) {
  span.login {
    display: block;
    float: none;
  }
}
